<template>
  <div class="main-box">
    <div style="height:60px"></div> <!-- 占位 -->
    <!-- 面包屑 -->
    <div class="menu-inner">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item>
          <router-link to="/study/study">学习中心</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item style="color: #15b7dd">证书信息</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="write-panel">
      <!-- 加载动态 -->
      <a-spin class="sping" v-show="loading " size="large" />
      <div class="side-item" style="margin-bottom:-5px">
        <h4 class="title">基本信息</h4>
        <div class="personnel-detail">
          <div class="li-item">
            <span class="li-title">姓名：</span>
            <a-input class="li-input" :disabled="true" v-model="form.name" />
          </div>
          <div class="li-item">
            <span class="li-title">身份证号：</span>
            <a-input class="li-input" :disabled="true" v-model="form.idcard"  />
          </div>
          <div class="li-item">
            <span class="li-title">性别：</span>
            <a-radio-group v-model="form.sex" :disabled="true" name="radioGroup">
              <a-radio :value="1">男</a-radio>
              <a-radio :value="0">女</a-radio>
            </a-radio-group>
          </div>
          <div class="li-item">
            <span class="li-title">联系方式：</span>
            <a-input class="li-input" :disabled="true" v-model="form.mobile" />
          </div>
          <div class="li-item">
            <span class="li-title">学历：</span>
            <a-input class="li-input" :disabled="true" v-model="form.education"  />
          </div>
          <div class="li-item">
            <span class="li-title">出生日期：</span>
            <a-date-picker class="li-input" :disabled="true" placeholder="请选择出生日期" valueFormat="YYYY-MM-DD" v-model="form.birthday" />
          </div>
          <div class="li-item">
            <span class="li-title">职称/职务：</span>
            <a-input class="li-input" :disabled="true" v-model="form.post"  />
          </div>
          <!-- <div class="li-item">
            <span class="li-title">工作单位：</span>
            <a-input class="li-input" :disabled="true" v-model="form.workUni"  />
          </div> -->
        </div>
      </div>

      <div class="side-item" v-if="form.mode == 1">
        <h4 class="title">邮寄纸质照片</h4>
        <div class="mail-detail">
          <!-- 物流信息 -->
          <div class="input-num">
            <p>快递公司：</p>
            <a-input v-model="form.logisticsCompany" :disabled="true" placeholder="请选择物流公司" />
          </div>
          <div class="input-num">
            <p>物流单号：</p>
            <a-input v-model="form.logisticsNumber" :disabled="true" placeholder="请上传物流单号" />
          </div>
        </div>
      </div>

      <div class="side-item" v-if="form.mode == 2">
        <h4 class="title">电子照片</h4>
        <div class="userimg-detail">
          <img :src="form.picture" alt="">
        </div>
      </div>

      <div class="side-item">
        <h4 class="title">邮寄地址</h4>
        <div class="address-detail">
          <img src="@/assets/image/study/icon_address_address.png" alt="">
          <div>
            <p class="name">{{form.userName}} <span class="phone">{{ form.userMobile ? form.userMobile.substr(0, 3) : '' }}****{{ form.userMobile?form.userMobile.substr(7, 11):'' }}</span></p>
            <p class="address">{{ form.userAddressText }}{{ form.userAddress }}</p>
          </div>
        </div>
      </div>
      <div class="submit-btn">
        <span class="cancel-btn" @click="$router.go(-1)">返回</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      signupUserId: 0,
      form:{},
      loading:false,
    }
  },
  // 事件处理器
  methods: {
    // 详情
    getDetail() {
      this.loading = true
      this.$ajax({
        url: '/hxclass-pc/exam/confirm-info',
        method: 'get',
        params: {
          signupUserId: this.signupUserId
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.form = res.data
        }
        this.loading = false
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.signupUserId = this.$AES.decode_data(this.$route.query.signupUserId)
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.getDetail()
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.menu-inner{
  /deep/ .ant-breadcrumb{
    line-height: 60px;
  }
}
.write-panel{
  background: #ffffff;
  padding:30px;
  min-height: 500px;
  border-radius: 5px;
  position: relative;
  .sping{
    width: 20px;
    height: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -10px;
    margin-left: -10px;
  }
  .title{
    font-size: 20px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #15B7DD;
    line-height: 23px;
    &::before{
      content: '';
      display: inline-block;
      width: 3px;
      height: 16px;
      background: #15B7DD;
      border-radius: 2px;
      margin-right: 4px;
      margin-bottom: -1px;
    }
  }
  .side-item{
    margin-bottom: 20px;
  }
  .personnel-detail{
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    .li-item{
      display: flex;
      align-items: center;
      margin-right: 24px;
      width: calc((100% - 100px) / 4);
      margin-bottom: 25px;
      .li-title{
        display: inline-block;
        min-width: 65px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
      }
      .li-input{
        flex: 1;
      }
    }
  }

  .mail-detail{
    margin-top: 20px;
    max-width: 400px;
    .input-num{
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 18px;
      margin-top: 12px;
      >p{
        min-width: 70px;
        text-align: left;
      }
      /deep/ .ant-input{
        background-color:#F5FDFF;
        border: 1px solid #B0F0FF;
      }
    }
  }
  .userimg-detail img{
    max-width: 120px;
    margin-top: 20px;
  }

  .address-detail{
    display: flex;
    align-items: center;
    margin-top: 20px;
    img{
      width: 41px;
      margin-right: 3px;
    }
    .name{
      font-size: 16px;
      font-family: PingFang HK-Medium, PingFang HK;
      font-weight: 500;
      color: #333333;
      line-height: 24px;
    }
    .phone{
      font-size: 12px;
      font-family: PingFang HK-Regular, PingFang HK;
      font-weight: 400;
      line-height: 18px;
    }
    .address{
      font-size: 12px;
      font-family: PingFang HK-Regular, PingFang HK;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
    }
  }
  .submit-btn{
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-top: 100px;
    .cancel-btn{
      text-align: center;
      border-radius: 23px;
      color: @theme;
      line-height: 34px;
      border: 1px solid @theme;
      width: 114px;
      height: 34px;
      margin-right: 24px;
    }
    span{
      width: 114px;
      font-size: 14px;
      line-height: 34px;
      height: 34px;
      display: inline-block;
      cursor: pointer;
    }
  }
}
</style>
